import { validationMixin } from "vuelidate/src";
import { TheMask } from "vue-the-mask";
const { required, email } = require("vuelidate/lib/validators");
const axios = require("axios");

export default {
  name: "appContact",
  mixins: [validationMixin],
  components: { TheMask },
  data() {
    return {
      payload: {
        name: "",
        email: "",
        phone: "",
        message: "",
        source: process.env.VUE_APP_SOURCE,
        public_key: process.env.VUE_APP_PUBLIC_KEY,
      },
      loading: false,
      error: false,
      type: 1,
      success: false,
    };
  },
  validations: {
    payload: {
      name: { required },
      email: { required, email },
      phone: { required },
      message: {},
    },
  },
  methods: {
    close: function() {
      this.$emit("close");
    },
    submit: async function() {
      this.loading = true;
      let data = this.payload;
      data.message =
        this.type === 1
          ? `Informes | ${data.message}`
          : `Colaboraciones | ${data.message}`;
      let url = "https://almedesarrollos.com/API/contacto.php";
      let response = await axios({
        headers: { "Accept-Language": "es_MX" },
        method: "POST",
        url: url,
        data,
      });

      if (response.status === 200) this.success = true;
      else this.error = true;
      this.loading = false;
    },
  },
};
