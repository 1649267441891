import contact from '../components/contact'
let vueScrollTo = require('vue-scrollto');

export default {
    name: 'app',
    components: {
       contact
    },
    data() {
        return {
            menu: false,
            contact: false,
            discover: 1,
            services: 1,
            header: true,
            container: null,
            lastPosition: 0,
            done: false,
            working: false,
            done_residential: 1,
            working_residential: 1,
            jacarandas: true
        }
    },
    methods: {
        slide: function() {
            this.discover = this.discover === 5? 1 : this.discover + 1;
        },
        displayHeader: function (evt) {
            this.header = evt.target.scrollTop < this.lastPosition;
            this.lastPosition = evt.target.scrollTop;
        },
        scrollTo: function(element) {
            console.log(element)
            let options = {
                container: '#container',
                easing: 'ease-in',
                lazy: false,
                offset: -75,
                force: true,
                cancelable: true,
                x: false,
                y: true
            }

            vueScrollTo.scrollTo(element, 500, options);
            this.menu = false;
        }
    },
    mounted() {
        setInterval(this.slide, 3000);
        this.container = document.getElementById('container')
        console.log(this.lastPosition);
        this.container.onscroll = this.displayHeader
    }
}